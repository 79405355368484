import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import teamsLogo from '../assets/teams-logo.png';
import aiIcon from '../assets/img/ai-icon.png';
import clockIcon from '../assets/img/icone-clock.png';
import accessDeniedIcon from '../assets/img/access-denied.png'; // Adicione o ícone de acesso negado
import { FaSearch, FaSync, FaChevronLeft, FaChevronRight, FaCalendarAlt } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import Sidebar from '../components/Sidebar';
import Notification from '../components/Notification';
import { Tooltip, OverlayTrigger, Dropdown } from 'react-bootstrap';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { subDays } from 'date-fns';
import { ptBR } from 'date-fns/locale'; // Importar a localidade ptBR
import { toast } from 'react-toastify';

const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

const Events = ({ user }) => {
    const [events, setEvents] = useState([]);
    const [allEvents, setAllEvents] = useState([]);
    const [summary, setSummary] = useState('');
    const [loading, setLoading] = useState({});
    const [isFetching, setIsFetching] = useState(false);
    const [dateRange, setDateRange] = useState([
        {
            startDate: null,
            endDate: null,
            key: 'selection'
        }
    ]);
    const [showCalendar, setShowCalendar] = useState(false);
    const [sortOrder, setSortOrder] = useState('asc');
    const [titleSortOrder, setTitleSortOrder] = useState('asc');
    const [searchQuery, setSearchQuery] = useState('');
    const [summarizedEvents, setSummarizedEvents] = useState(new Set());
    const [notifications, setNotifications] = useState([]);
    const [isAnimating, setIsAnimating] = useState(false); // Estado para controle de animação
    const [selectedTemporalFilter, setSelectedTemporalFilter] = useState(''); // Estado para filtro temporal
    const history = useHistory();
    const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const calendarRef = useRef(null);

    // Adicione um estado para controlar o erro de vídeo
    const [videoErrors, setVideoErrors] = useState({});

    useEffect(() => {
        const token = localStorage.getItem('accessToken');
        if (!token) {
            history.push('/login');
        } else {
            fetchEvents();
            fetchNotifications();
        }
    }, [dateRange]);

    useEffect(() => {
        updateDisplayedEvents();
    }, [allEvents, currentPage, itemsPerPage]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (calendarRef.current && !calendarRef.current.contains(event.target)) {
                setShowCalendar(false);
            }
        };

        if (showCalendar) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showCalendar]);

    axios.interceptors.response.use(
        response => response,
        error => {
            if (error.response && error.response.status === 401) {
                localStorage.removeItem('accessToken');
                history.push('/login');
            }
            return Promise.reject(error);
        }
    );

    const fetchEvents = async () => {
        setIsFetching(true);
        try {
            const token = localStorage.getItem('accessToken');
            const eventsResponse = await axios.get(`${REACT_APP_API_URL}/api/events`, {
                headers: { Authorization: `Bearer ${token}` },
                withCredentials: true,
                params: {
                    startDate: dateRange[0].startDate ? dateRange[0].startDate.toISOString() : undefined,
                    endDate: dateRange[0].endDate ? new Date(dateRange[0].endDate.setHours(23, 59, 59, 999)).toISOString() : undefined, // Ajusta o horário da endDate
                }
            });

            setAllEvents(eventsResponse.data);
            setEvents(eventsResponse.data.slice(0, itemsPerPage));
        } catch (error) {
            console.error("Error fetching events:", error.message);
        } finally {
            setIsFetching(false);
        }
    };

    const fetchNotifications = async () => {
        try {
            const token = localStorage.getItem('accessToken');
            const notificationsResponse = await axios.get(`${REACT_APP_API_URL}/api/notifications`, {
                headers: { Authorization: `Bearer ${token}` },
                withCredentials: true,
                params: { userId: user.id }
            });

            setNotifications(notificationsResponse.data);
        } catch (error) {
            console.error("Error fetching notifications:", error.message);
        }
    };

    const searchEvents = async () => {
        setIsFetching(true);
        try {
            const token = localStorage.getItem('accessToken');
            const searchResponse = await axios.get(`${REACT_APP_API_URL}/api/events/search`, {
                headers: { Authorization: `Bearer ${token}` },
                withCredentials: true,
                params: { searchQuery }
            });

            setAllEvents(searchResponse.data);
            setEvents(searchResponse.data.slice(0, itemsPerPage));
            setCurrentPage(1);
        } catch (error) {
            console.error("Error searching events:", error.message);
        } finally {
            setIsFetching(false);
        }
    };

    const handleReload = async () => {
        await fetchEvents();
        await fetchNotifications();
    };

    const handleSummarize = async (transcriptContentUrl, event) => {
        setLoading(prev => ({ ...prev, [event.id]: true }));
        toast.info(`O resumo para a reunião "${event.subject}" está sendo gerado. Você será notificado quando estiver pronto.`);
        try {
            const token = localStorage.getItem('accessToken');
            const { data: task } = await axios.post(`${REACT_APP_API_URL}/api/summary/startSummarize`, {
                transcriptContentUrl: transcriptContentUrl,
                accessToken: token
            });
            const taskId = task.taskId;
    
            const checkStatus = async () => {
                const { data: status } = await axios.get(`${REACT_APP_API_URL}/api/summary/status/${taskId}`);
                if (status.state === 'completed') {
                    const finalSummary = status.result.summary;
                    const transcriptionCreatedDateTime = new Date(event.transcriptionCreatedDateTime).toISOString().slice(0, 19).replace('T', ' ');
    
                    const saveResponse = await axios.post(`${REACT_APP_API_URL}/api/summary/save`, {
                        meetingId: event.id,
                        subject: event.subject,
                        joinUrl: event.joinUrl,
                        transcriptionUrl: transcriptContentUrl,
                        transcriptionContent: status.result.transcriptText,
                        transcriptionCreatedDateTime: transcriptionCreatedDateTime,
                        summary: finalSummary,
                        userId: user.id,
                        userName: user.name,
                        userUpn: user.email,
                        recordingUrl: event.recordingContentUrl || null
                    });
    
                    const reportId = saveResponse.data.reportId;
    
                    // Garantir que o registro no video_downloads seja criado mesmo sem vídeo
                    if (!event.recordingContentUrl) {
                        await axios.post(`${REACT_APP_API_URL}/api/summary/createVideoDownloadRecord`, {
                            reportId: reportId,
                            videoId: null,
                            indexerResponse: null,
                            videoFileName: null
                        });
                    }
    
                    if (event.recordingContentUrl) {
                        // Chamada para download do vídeo
                        try {
                            const downloadResponse = await axios.post(`${REACT_APP_API_URL}/api/summary/downloadVideo`, {
                                recordingUrl: event.recordingContentUrl,
                                accessToken: token,
                                reportId: reportId // Usar o reportId (id da reunião salva)
                            });
    
                            const { fileName } = downloadResponse.data;
    
                            // Chamada para indexar o vídeo
                            await axios.post(`${REACT_APP_API_URL}/api/videoindexer/index`, {
                                videoFileName: fileName, // Usar o nome do arquivo retornado
                                reportId: reportId
                            });
                        } catch (downloadError) {
                            console.error("Error downloading video:", downloadError.response ? downloadError.response.data : downloadError.message);
                            toast.error(`Erro ao baixar o vídeo para a reunião "${event.subject}". Acesso negado. O resumo foi gerado sem o vídeo.`);
                        }
                    }
    
                    setSummary(finalSummary);
                    const newNotification = {
                        id: reportId,
                        report_id: reportId,
                        message: `Seu resumo do ${event.subject} está pronto. Clique para ver.`
                    };
                    setNotifications(prev => [...prev, newNotification]);
                    setSummarizedEvents(prev => new Set(prev).add(event.id));
                    toast.success(`O resumo para a reunião "${event.subject}" está pronto. Clique na notificação para ver.`);
                } else if (status.state === 'failed') {
                    console.error("Error summarizing transcript:", status.error);
                    toast.error(`Erro ao gerar resumo para a reunião "${event.subject}".`);
                } else {
                    setTimeout(checkStatus, 5000); // Verifica o status a cada 5 segundos
                }
            };
    
            checkStatus();
        } catch (error) {
            console.error("Error starting summarize:", error.response ? error.response.data : error.message);
            toast.error(`Erro ao iniciar a sumarização para a reunião "${event.subject}".`);
        } finally {
            setLoading(prev => ({ ...prev, [event.id]: false }));
        }
    };
    
    
    const handleDateChange = (ranges) => {
        setDateRange([ranges.selection]);
        setSelectedTemporalFilter(''); // Resetar o filtro temporal quando a data é selecionada manualmente
    };

    const handleFilter = () => {
        const startDate = new Date(dateRange[0].startDate.setHours(0, 0, 0, 0)); // Início do dia
        const endDate = new Date(dateRange[0].endDate.setHours(23, 59, 59, 999)); // Fim do dia
        if (endDate - startDate <= 2 * 24 * 60 * 60 * 1000) {
            setDateRange([{ startDate, endDate, key: 'selection' }]);
            fetchEvents();
        } else {
            alert('Por favor, selecione um intervalo de no máximo 2 dias.');
        }
    };

    const handleTemporalFilterChange = (filter) => {
        setIsFetching(true); // Ativar o estado de carregamento
        let startDate;
        const endDate = new Date();

        switch (filter) {
            case 'Hoje':
                startDate = new Date();
                break;
            case 'Últimos 3 dias':
                startDate = subDays(new Date(), 2);
                break;
            case 'Últimos 7 dias':
                startDate = subDays(new Date(), 6);
                break;
            default:
                setIsFetching(false); // Desativar o estado de carregamento em caso de erro
                return;
        }

        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(23, 59, 59, 999);

        setDateRange([{ startDate, endDate, key: 'selection' }]);
        setSelectedTemporalFilter(filter);
        fetchEvents().finally(() => setIsFetching(false)); // Desativar o estado de carregamento após a busca
    };

    const handleSortOrder = () => {
        const sortedEvents = [...allEvents].sort((a, b) => {
            const dateA = new Date(a.transcriptionCreatedDateTime);
            const dateB = new Date(b.transcriptionCreatedDateTime);
            return sortOrder === 'asc' ? dateB - dateA : dateA - dateB;
        });
        setAllEvents(sortedEvents);
        updateDisplayedEvents();
        setSortOrder(prevOrder => (prevOrder === 'asc' ? 'desc' : 'asc'));
    };

    const handleTitleSortOrder = () => {
        const sortedEvents = [...allEvents].sort((a, b) => {
            const titleA = a.subject.toLowerCase();
            const titleB = b.subject.toLowerCase();
            if (titleA < titleB) return titleSortOrder === 'asc' ? -1 : 1;
            if (titleA > titleB) return titleSortOrder === 'asc' ? 1 : -1;
            return 0;
        });
        setAllEvents(sortedEvents);
        updateDisplayedEvents();
        setTitleSortOrder(prevOrder => (prevOrder === 'asc' ? 'desc' : 'asc'));
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            searchEvents();
        }
    };

    const handleNotificationClick = (notification) => {
        history.push(`/report/${notification.report_id}`);
        setNotifications(prev => prev.filter(notif => notif !== notification));
    };

    const truncateTitle = (title, maxLength) => {
        if (title.length > maxLength) {
            return `${title.substring(0, maxLength)}...`;
        }
        return title;
    };

    const formatDateTime = (dateTime) => {
        const date = new Date(dateTime);
        const options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
        const formattedDate = date.toLocaleDateString('pt-BR', options);
        const formattedTime = date.toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' });
        return { formattedDate, formattedTime };
    };

    // Função para verificar o status do vídeo
    const checkVideoStatus = async (url, eventId) => {
        try {
            const response = await axios.head(url);
            if (response.status !== 200) {
                setVideoErrors(prev => ({ ...prev, [eventId]: true }));
            }
        } catch (error) {
            setVideoErrors(prev => ({ ...prev, [eventId]: true }));
        }
    };

    const handleItemsPerPageChange = (e) => {
        setItemsPerPage(parseInt(e.target.value, 10));
        setCurrentPage(1);
        updateDisplayedEvents();
    };

    const handleNextPage = () => {
        setCurrentPage(prevPage => prevPage + 1);
        updateDisplayedEvents();
    };

    const handlePreviousPage = () => {
        setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
        updateDisplayedEvents();
    };

    const updateDisplayedEvents = () => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        setEvents(allEvents.slice(startIndex, endIndex));
    };

    const formatRangeDate = () => {
        const { startDate, endDate } = dateRange[0];
        if (!startDate || !endDate) {
            return "Escolher Data";
        }
        const start = startDate.toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' });
        const end = endDate.toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' });
        return `${start} - ${end}`;
    };

    const handleButtonClick = () => {
        setIsAnimating(true);
        setTimeout(() => setIsAnimating(false), 300); // Duração da animação
        setShowCalendar(!showCalendar);
    };

    const clearDateFilter = () => {
        setDateRange([{ startDate: null, endDate: null, key: 'selection' }]);
        setSelectedTemporalFilter('');
        fetchEvents();
    };

    return (
        <div className="d-flex">
            <Sidebar user={user} onToggleSidebar={setIsSidebarCollapsed} />
            <div className="content" style={{ marginLeft: isSidebarCollapsed ? '80px' : '250px', width: '100%', transition: 'margin-left 0.3s' }}>
                <div className="d-flex justify-content-between align-items-center mb-4" style={{ backgroundColor: '#e0e0e0', padding: '10px', borderRadius: '5px', margin: '0 -0px -10px -20px' }}>
                    <h2 style={{ marginLeft: '30px', marginTop: '10px', fontSize: '20px' }}>Relatórios</h2>
                    <div className="d-flex align-items-center">
                        <div className="position-relative mr-3">
                            <input 
                                type="text" 
                                value={searchQuery} 
                                onChange={e => setSearchQuery(e.target.value)} 
                                onKeyPress={handleKeyPress}
                                className="form-control" 
                                placeholder="Pesquisar reuniões..." 
                                style={{ width: '400px', paddingRight: '40px' }} 
                            />
                            <OverlayTrigger
                                placement="bottom"
                                overlay={<Tooltip id="button-tooltip">Buscar reunião</Tooltip>}
                            >
                                <FaSearch 
                                    onClick={searchEvents}
                                    style={{
                                        position: 'absolute',
                                        right: '10px',
                                        top: '50%',
                                        transform: 'translateY(-50%)',
                                        cursor: 'pointer',
                                        color: '#6c757d'
                                    }}
                                />
                            </OverlayTrigger>
                        </div>
                        <div className="d-flex align-items-center border" style={{ borderRadius: '5px', borderColor: '#e0e0e0', padding: '5px 10px' }}>
                            <Dropdown onSelect={handleTemporalFilterChange} className="mr-3">
                                <Dropdown.Toggle variant="light" id="dropdown-basic">
                                    {selectedTemporalFilter || 'Escolher Data'}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item eventKey="Hoje">Hoje</Dropdown.Item>
                                    <Dropdown.Item eventKey="Últimos 3 dias">Últimos 3 dias</Dropdown.Item>
                                    <Dropdown.Item eventKey="Últimos 7 dias">Últimos 7 dias</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            <div className="vr" style={{ height: '28px', alignSelf: 'center', borderColor: '#e0e0e0', marginLeft: '10px', marginRight: '10px' }}></div>
                            <OverlayTrigger
                                placement="bottom"
                                overlay={<Tooltip id="button-tooltip">Escolher Data</Tooltip>}
                            >
                                <button 
                                    className="btn btn-light"
                                    onClick={handleButtonClick}
                                    style={{
                                        color: '#6c757d', // cor da fonte cinza
                                        transform: isAnimating ? 'scale(1.1)' : 'scale(1)',
                                        transition: 'transform 0.2s'
                                    }}
                                >
                                    <FaCalendarAlt /> {formatRangeDate()}
                                </button>
                            </OverlayTrigger>
                            {showCalendar && (
                                <div ref={calendarRef} className="position-absolute" style={{ zIndex: 1000, backgroundColor: 'white', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', borderRadius: '5px', top: '50px' }}>
                                    <DateRange
                                        ranges={dateRange}
                                        onChange={handleDateChange}
                                        showSelectionPreview={true}
                                        moveRangeOnFirstSelection={false}
                                        months={1}
                                        direction="horizontal"
                                        locale={ptBR} // Definir a localidade para Português do Brasil
                                        className="mr-2"
                                    />
                                    {/* <button onClick={handleFilter} className="btn btn-primary mt-2">Filtrar</button> */}
                                    <button onClick={clearDateFilter} className="btn btn-secondary mt-2 ml-2" >Limpar</button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <hr style={{ borderColor: '#e0e0e0' }} />
                <div className="d-flex justify-content-end align-items-center mb-4">
                    <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip id="button-tooltip">Recarregar</Tooltip>}
                    >
                        <button onClick={handleReload} className="btn btn-link" style={{ textDecoration: 'none', color: '#4c47cc', marginRight: '10px' }}>
                            <FaSync style={{ marginRight: '5px' }} /> Recarregar
                        </button>
                    </OverlayTrigger>
                    <Notification notifications={notifications} onNotificationClick={handleNotificationClick} />
                </div>
                {isFetching ? (
                    <div className="d-flex justify-content-center align-items-center" style={{ height: '300px' }}>
                        <div className="spinner-border text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                ) : (
                    <>
                        <div className="list-group">
                            <div className="list-group-item bg-light d-flex justify-content-between align-items-center">
                                <span style={{ flex: 1 }}>Origem</span>
                                <span style={{ flex: 2, cursor: 'pointer' }} onClick={handleTitleSortOrder}>
                                    Reunião {titleSortOrder === 'asc' ? '▲' : '▼'}
                                </span>
                                <span style={{ flex: 1, cursor: 'pointer' }} onClick={handleSortOrder}>
                                    Data {sortOrder === 'asc' ? '▲' : '▼'}
                                </span>
                                <span style={{ flex: 1 }}>Tags</span>
                                <span style={{ flex: 1 }}>GenAI</span>
                            </div>
                            {!searchQuery && !dateRange[0].startDate && !dateRange[0].endDate && allEvents.length === 0 && (
                                <div className="d-flex flex-column align-items-center justify-content-center" style={{ height: '300px', border: '1px solid #e0e0e0', borderRadius: '10px', backgroundColor: '#f8f9fa',  marginTop: '20px', marginLeft: '20px', marginRight: '20px' }}>
                                    <FaCalendarAlt size={30} color="#007bff" style={{ marginBottom: '10px' }} />
                                    <p style={{ color: '#6c757d', fontSize: '14px' }}>Nenhum filtro selecionado.</p>
                                    <p style={{ color: '#6c757d', fontSize: '14px' }}>Por favor, aplique um filtro para ver as reuniões.</p>
                                </div>
                            )}
                            {events.map(event => (
                                <div key={event.id} className="list-group-item mb-3 rounded shadow-sm" style={{ transition: 'transform 0.2s, box-shadow 0.2s' }} onMouseEnter={e => { e.currentTarget.style.transform = 'translateY(-5px)'; e.currentTarget.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.1)'; }} onMouseLeave={e => { e.currentTarget.style.transform = 'translateY(0)'; e.currentTarget.style.boxShadow = '0 1px 2px rgba(0, 0, 0, 0.05)'; }}>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div style={{ flex: 1 }}>
                                            {event.recordingContentUrl ? (
                                                videoErrors[event.id] ? (
                                                    <img src={accessDeniedIcon} alt="Access Denied" style={{ width: '40px', height: '40px', marginRight: '10px' }} />
                                                ) : (
                                                    <video
                                                        width="100"
                                                        controls
                                                        onError={() => setVideoErrors(prev => ({ ...prev, [event.id]: true }))}
                                                        onLoadedData={() => setVideoErrors(prev => ({ ...prev, [event.id]: false }))}
                                                    >
                                                        <source src={`${REACT_APP_API_URL}/api/recording/get-recording?recordingUrl=${encodeURIComponent(event.recordingContentUrl)}`} type="video/mp4" />
                                                        Your browser does not support the video tag.
                                                    </video>
                                                )
                                            ) : (
                                                <img src={teamsLogo} alt="Teams Logo" style={{ width: '40px', marginRight: '10px' }} />
                                            )}
                                        </div>
                                        <div style={{ flex: 2 }}>
                                            <h6 className="mb-0" title={event.subject} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                {truncateTitle(event.subject, 30)}
                                            </h6>
                                        </div>
                                        <div style={{ flex: 1, textAlign: 'left', fontSize: '12px' }}>
                                            {event.transcriptionCreatedDateTime ? (
                                                <>
                                                    <div>{formatDateTime(event.transcriptionCreatedDateTime).formattedDate}</div>
                                                    <div className="d-flex align-items-center">
                                                        <span>{formatDateTime(event.transcriptionCreatedDateTime).formattedTime}</span>
                                                        <img
                                                            src={clockIcon}
                                                            alt="Clock Icon"
                                                            style={{ width: '15px', height: '15px', marginLeft: '5px' }}
                                                        />
                                                    </div>
                                                </>
                                            ) : 'No date available'}
                                        </div>
                                        <div style={{ flex: 1 }}>
                                            {/* Placeholder for Tags */}
                                        </div>
                                        <div style={{ flex: 1 }}>
                                            {event.transcriptContentUrl ? (
                                                <OverlayTrigger
                                                    placement="bottom"
                                                    overlay={<Tooltip id="summarize-tooltip">Gerar Resumo com IA</Tooltip>}
                                                >
                                                    <img
                                                        src={aiIcon}
                                                        alt="Summarize"
                                                        onClick={() => handleSummarize(event.transcriptContentUrl, event)}
                                                        className={`ml-2 ${loading[event.id] ? 'spinner-border' : ''}`}
                                                        style={{ width: '50px', height: '50px', cursor: 'pointer' }}
                                                        disabled={summarizedEvents.has(event.id)}
                                                    />
                                                </OverlayTrigger>
                                            ) : (
                                                <div style={{ width: '50px', height: '50px', marginLeft: '10px' }}></div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="d-flex justify-content-end align-items-center mt-3">
                            <span>{`${(currentPage - 1) * itemsPerPage + 1}-${Math.min(currentPage * itemsPerPage, allEvents.length)} de ${allEvents.length}`}</span>
                            <div className="btn-group ml-3">
                                <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip id="button-tooltip">Página anterior</Tooltip>}
                                >
                                    <button className="btn btn-secondary" onClick={handlePreviousPage} disabled={currentPage === 1} style={{ backgroundColor: 'white', borderColor: '#e0e0e0', color: '#6c757d', marginRight: '2px' }}>
                                        <FaChevronLeft />
                                    </button>
                                </OverlayTrigger>
                                <select id="itemsPerPage" className="form-control" value={itemsPerPage} onChange={handleItemsPerPageChange} style={{ maxWidth: '70px', borderColor: '#e0e0e0', marginRight: '2px' }}>
                                    <option value={5}>5</option>
                                    <option value={10}>10</option>
                                    <option value={15}>15</option>
                                </select>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip id="button-tooltip">Próxima página</Tooltip>}
                                >
                                    <button className="btn btn-secondary" onClick={handleNextPage} style={{ backgroundColor: 'white', borderColor: '#e0e0e0', color: '#6c757d', marginRight: '20px' }}>
                                        <FaChevronRight />
                                    </button>
                                </OverlayTrigger>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default Events;
