import React, { useState, useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import teamsLogo from '../assets/teams-logo.png';
import { FaCalendarAlt, FaChartBar, FaUsers, FaUserShield, FaFileAlt, FaCog, FaQuestionCircle, FaSignOutAlt, FaChevronLeft, FaChevronRight, FaTools } from 'react-icons/fa';
import { NavLink, useLocation } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const Sidebar = ({ user, onToggleSidebar }) => {
    const [showUserMenu, setShowUserMenu] = useState(false);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const userMenuRef = useRef(null);
    const location = useLocation();

    const toggleUserMenu = () => {
        setShowUserMenu(!showUserMenu);
    };

    const handleLogout = () => {
        window.location.href = `${process.env.REACT_APP_API_URL}/api/auth/azuread/logout`;
    };

    const getInitials = (name) => {
        if (!name) return '';
        const names = name.split(' ');
        const initials = names[0][0] + names[names.length - 1][0];
        return initials.toUpperCase();
    };

    const getFirstAndLastName = (name) => {
        if (!name) return '';
        const names = name.split(' ');
        return `${names[0]} ${names[names.length - 1]}`;
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (userMenuRef.current && !userMenuRef.current.contains(event.target)) {
                setShowUserMenu(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const toggleSidebar = () => {
        setIsCollapsed(!isCollapsed);
        onToggleSidebar(!isCollapsed);
    };

    const handleMouseEnter = () => {
        setIsHovered(true);
        onToggleSidebar(false);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
        if (isCollapsed) {
            onToggleSidebar(true);
        }
    };

    return (
        <div
            className={`d-flex flex-column bg-dark text-white ${isCollapsed && !isHovered ? 'collapsed' : ''}`}
            style={{
                width: isCollapsed && !isHovered ? '80px' : '250px',
                height: '100vh',
                position: 'fixed',
                transition: 'width 0.3s',
                zIndex: 1
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <div className="d-flex flex-column flex-grow-1">
                <div className="d-flex align-items-center justify-content-between py-3 px-3">
                    <img src={teamsLogo} alt="Product Logo" className="img-fluid" style={{ width: isCollapsed && !isHovered ? '50px' : '100px', transition: 'width 0.3s' }} />
                    <button
                        onClick={toggleSidebar}
                        className="btn btn-link text-white p-0"
                        style={{
                            fontSize: '1.5rem',
                            textDecoration: 'none',
                            lineHeight: '0',
                            marginLeft: isCollapsed ? 'auto' : '0'
                        }}
                    >
                        {isCollapsed ? <FaChevronRight /> : <FaChevronLeft />}
                    </button>
                </div>
                <hr className="border-secondary my-0" />
                <div className="flex-grow-1">
                    <div className="p-3">
                        <div className="mb-4">
                            <NavLink
                                to="/events"
                                className="text-white d-flex align-items-center justify-content-between nav-link"
                                exact
                                style={{ cursor: 'pointer', transition: 'background-color 0.3s', borderRadius: '6px', padding: '10px 15px', textDecoration: 'none' }}
                                activeClassName="active"
                                onMouseEnter={e => e.currentTarget.style.backgroundColor = '#7b84ec'}
                                onMouseLeave={e => e.currentTarget.style.backgroundColor = 'transparent'}
                            >
                                <div className="d-flex align-items-center">
                                    <FaUsers className="me-2" />
                                    {(isHovered || !isCollapsed) && <span style={{ fontStyle: 'normal' }}>Reuniões</span>}
                                </div>
                            </NavLink>
                        </div>
                        <div className="mb-4">
                            <OverlayTrigger
                                placement="right"
                                overlay={<Tooltip id="calendar-tooltip">Página em construção</Tooltip>}
                            >
                                <NavLink
                                    to="#"
                                    className="text-white d-flex align-items-center justify-content-between nav-link"
                                    style={{ cursor: 'pointer', transition: 'background-color 0.3s', borderRadius: '6px', padding: '10px 15px', textDecoration: 'none' }}
                                    activeClassName="active"
                                    onMouseEnter={e => e.currentTarget.style.backgroundColor = '#7b84ec'}
                                    onMouseLeave={e => e.currentTarget.style.backgroundColor = 'transparent'}
                                >
                                    <div className="d-flex align-items-center">
                                        <FaCalendarAlt className="me-2" />
                                        {(isHovered || !isCollapsed) && <span style={{ fontStyle: 'normal' }}>Calendário</span>}
                                    </div>
                                    <FaTools />
                                </NavLink>
                            </OverlayTrigger>
                        </div>
                        <div className="mb-4">
                            <NavLink
                                to="/reports"
                                className="text-white d-flex align-items-center justify-content-between nav-link"
                                style={{ cursor: 'pointer', transition: 'background-color 0.3s', borderRadius: '6px', padding: '10px 15px', textDecoration: 'none' }}
                                activeClassName="active"
                                onMouseEnter={e => e.currentTarget.style.backgroundColor = '#7b84ec'}
                                onMouseLeave={e => e.currentTarget.style.backgroundColor = 'transparent'}
                            >
                                <div className="d-flex align-items-center">
                                    <FaFileAlt className="me-2" />
                                    {(isHovered || !isCollapsed) && <span style={{ fontStyle: 'normal' }}>Relatórios</span>}
                                </div>
                            </NavLink>
                        </div>
                    </div>
                    <hr className="border-secondary my-0" />
                    <div className="p-3">
                        <div className="mb-4">
                            <OverlayTrigger
                                placement="right"
                                overlay={<Tooltip id="insights-tooltip">Página em construção</Tooltip>}
                            >
                                <NavLink
                                    to="#"
                                    className="text-white d-flex align-items-center justify-content-between nav-link"
                                    style={{ cursor: 'pointer', transition: 'background-color 0.3s', borderRadius: '6px', padding: '10px 15px', textDecoration: 'none' }}
                                    activeClassName="active"
                                    onMouseEnter={e => e.currentTarget.style.backgroundColor = '#7b84ec'}
                                    onMouseLeave={e => e.currentTarget.style.backgroundColor = 'transparent'}
                                >
                                    <div className="d-flex align-items-center">
                                        <FaChartBar className="me-2" />
                                        {(isHovered || !isCollapsed) && <span style={{ fontStyle: 'normal' }}>Insights</span>}
                                    </div>
                                    <FaTools />
                                </NavLink>
                            </OverlayTrigger>
                        </div>
                        <div className="mb-4">
                            <OverlayTrigger
                                placement="right"
                                overlay={<Tooltip id="meeting-policy-tooltip">Página em construção</Tooltip>}
                            >
                                <NavLink
                                    to="#"
                                    className="text-white d-flex align-items-center justify-content-between nav-link"
                                    style={{ cursor: 'pointer', transition: 'background-color 0.3s', borderRadius: '6px', padding: '10px 15px', textDecoration: 'none' }}
                                    activeClassName="active"
                                    onMouseEnter={e => e.currentTarget.style.backgroundColor = '#7b84ec'}
                                    onMouseLeave={e => e.currentTarget.style.backgroundColor = 'transparent'}
                                >
                                    <div className="d-flex align-items-center">
                                        <FaUserShield className="me-2" />
                                        {(isHovered || !isCollapsed) && <span style={{ fontStyle: 'normal' }}>Políticas da Reunião</span>}
                                    </div>
                                    <FaTools />
                                </NavLink>
                            </OverlayTrigger>
                        </div>
                        <div className="mb-4">
                            <OverlayTrigger
                                placement="right"
                                overlay={<Tooltip id="for-you-tooltip">Página em construção</Tooltip>}
                            >
                                <NavLink
                                    to="#"
                                    className="text-white d-flex align-items-center justify-content-between nav-link"
                                    style={{ cursor: 'pointer', transition: 'background-color 0.3s', borderRadius: '6px', padding: '10px 15px', textDecoration: 'none' }}
                                    activeClassName="active"
                                    onMouseEnter={e => e.currentTarget.style.backgroundColor = '#7b84ec'}
                                    onMouseLeave={e => e.currentTarget.style.backgroundColor = 'transparent'}
                                >
                                    <div className="d-flex align-items-center">
                                        <FaFileAlt className="me-2" />
                                        {(isHovered || !isCollapsed) && <span style={{ fontStyle: 'normal' }}>Para Você</span>}
                                    </div>
                                    <FaTools />
                                </NavLink>
                            </OverlayTrigger>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-auto p-3 position-relative">
                <hr className="border-secondary my-0" />
                <div
                    className="mt-2 d-flex align-items-center user-info"
                    style={{
                        fontSize: '14px',
                        cursor: 'pointer',
                        transition: 'background-color 0.3s',
                        borderRadius: '12px',
                        padding: '10px'
                    }}
                    onClick={toggleUserMenu}
                    onMouseEnter={e => e.currentTarget.style.backgroundColor = '#7b84ec'}
                    onMouseLeave={e => e.currentTarget.style.backgroundColor = 'transparent'}
                >
                    <div
                        style={{
                            width: '40px',
                            height: '40px',
                            borderRadius: '50%',
                            backgroundColor: '#4c47cc',
                            color: 'white',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginRight: '10px'
                        }}
                    >
                        {getInitials(user.name)}
                    </div>
                    {(isHovered || !isCollapsed) && (
                        <div>
                            {getFirstAndLastName(user.name)}
                            <div style={{ fontSize: '12px', wordBreak: 'break-all' }}>
                                {user.email}
                            </div>
                        </div>
                    )}
                </div>
                {showUserMenu && (
                    <div ref={userMenuRef} className="bg-white text-dark p-2 rounded shadow position-absolute" style={{ top: '50%', left: isCollapsed ? '80px' : '250px', transform: 'translateY(-50%)', zIndex: '1000', width: '200px' }}>
                        <div className="text-dark d-flex align-items-center mb-2" style={{ cursor: 'pointer' }} onMouseEnter={e => e.currentTarget.style.backgroundColor = '#f0f0f0'} onMouseLeave={e => e.currentTarget.style.backgroundColor = 'transparent'}>
                            <FaCog className="me-2" /> Configurações
                        </div>
                        <div className="text-dark d-flex align-items-center mb-2" style={{ cursor: 'pointer' }} onMouseEnter={e => e.currentTarget.style.backgroundColor = '#f0f0f0'} onMouseLeave={e => e.currentTarget.style.backgroundColor = 'transparent'}>
                            <FaQuestionCircle className="me-2" /> Suporte
                        </div>
                        <div className="text-dark d-flex align-items-center" style={{ cursor: 'pointer' }} onClick={handleLogout} onMouseEnter={e => e.currentTarget.style.backgroundColor = '#f0f0f0'} onMouseLeave={e => e.currentTarget.style.backgroundColor = 'transparent'}>
                            <FaSignOutAlt className="me-2" /> Logout
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Sidebar;
