import React, { useState, useEffect } from 'react';
import { FaRegCommentDots } from 'react-icons/fa';  // Ícones de mensagem
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Notification = ({ notifications, onNotificationClick }) => {
    const [show, setShow] = useState(false);

    const handleToggle = (isOpen) => {
        setShow(isOpen);
    };

    const markAsRead = async (notificationId) => {
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/api/notifications/mark-as-read`, { notificationId }, {
                withCredentials: true
            });
        } catch (error) {
            console.error('Error marking notification as read:', error);
        }
    };

    const handleClick = (notification) => {
        markAsRead(notification.id);
        onNotificationClick(notification);
    };

    useEffect(() => {
        notifications.forEach(notification => {
            toast.info(`Nova notificação: ${notification.message}`);
        });
    }, [notifications]);

    return (
        <>
            <Dropdown show={show} onToggle={handleToggle} className="position-relative" style={{ marginRight: '20px' }}>
                <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip id="notification-tooltip">Ver notificações</Tooltip>}
                >
                    <Dropdown.Toggle variant="link" id="dropdown-notification">
                        <FaRegCommentDots className="text-primary" style={{ fontSize: '24px', cursor: 'pointer' }} /> {/* Ícone de mensagem */}
                        {notifications.length > 0 && (
                            <span className="badge badge-danger position-absolute" style={{ top: '-10px', right: '-10px' }}>
                                {notifications.length}
                            </span>
                        )}
                    </Dropdown.Toggle>
                </OverlayTrigger>

                <Dropdown.Menu align="right" style={{ width: '300px' }}>
                    <Dropdown.Header>Notificações</Dropdown.Header>
                    {notifications.length > 0 ? (
                        notifications.map((notification, index) => (
                            <Dropdown.Item 
                                as={Link} 
                                to={`/report/${notification.report_id}`} 
                                key={index} 
                                onClick={() => handleClick(notification)}
                                style={{ 
                                    borderRadius: '10px', 
                                    backgroundColor: index % 2 === 0 ? '#f8f9fa' : '#e9ecef', // Intercalar as cores de fundo
                                    marginBottom: '5px', 
                                    whiteSpace: 'normal', 
                                    wordWrap: 'break-word', 
                                    fontSize: '14px' 
                                }}
                            >
                                <strong>{notification.message.split(' ')[3]}</strong> {/* Destaque o subject */}
                                {notification.message.replace(notification.message.split(' ')[3], '')}
                            </Dropdown.Item>
                        ))
                    ) : (
                        <Dropdown.Item 
                            style={{ 
                                borderRadius: '10px', 
                                backgroundColor: '#f8f9fa', 
                                whiteSpace: 'normal', 
                                wordWrap: 'break-word', 
                                fontSize: '14px' 
                            }}
                        >
                            Você não tem novas notificações.
                        </Dropdown.Item>
                    )}
                </Dropdown.Menu>
            </Dropdown>
            <ToastContainer autoClose={9000} /> {/* Container do Toastify */}
        </>
    );
};

export default Notification;
