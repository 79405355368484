// App.js
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import Login from './pages/Login';
import Events from './pages/Events';
import Reports from './pages/Reports';
import ReportDetail from './pages/ReportDetail';
import PrivateRoute from './components/PrivateRoute';
import { jwtDecode } from 'jwt-decode';
import 'bootstrap/dist/css/bootstrap.min.css';
import MobileEvents from './mobile/pages/MobileEvents';
import MobileReports from './mobile/pages/MobileReports';
import MobileReportDetail from './mobile/pages/MobileReportDetail';
import { isMobile } from './utils/isMobile';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
    const [user, setUser] = useState({});

    useEffect(() => {
        const token = localStorage.getItem('accessToken');
        if (token) {
            try {
                const decodedToken = jwtDecode(token);
                console.log("Decoded Token:", decodedToken);

                setUser({
                    id: decodedToken.oid,
                    name: decodedToken.name || '',
                    email: decodedToken.preferred_username || decodedToken.upn || '',
                });

                console.log("User Information:", {
                    id: decodedToken.oid,
                    name: decodedToken.name || '',
                    email: decodedToken.preferred_username || decodedToken.upn || ''
                });
            } catch (error) {
                console.error("Error decoding token:", error);
            }
        }
    }, []);

    return (
        <Router>
            <Switch>
                <Route exact path="/" component={Login} />
                <PrivateRoute path="/events" component={() => isMobile() ? <MobileEvents user={user} /> : <Events user={user} />} />
                <PrivateRoute path="/reports" component={() => isMobile() ? <MobileReports user={user} /> : <Reports user={user} />} />
                <PrivateRoute path="/report/:id" component={() => isMobile() ? <MobileReportDetail user={user} /> : <ReportDetail user={user} />} />
                <Redirect to="/" />
            </Switch>
        </Router>
    );
};

export default App;
