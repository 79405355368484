// components/MobileSidebar.js
import React, { useState, useRef, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import teamsLogo from '../../assets/teams-logo.png';
import { FaCalendarAlt, FaChartBar, FaUsers, FaUserShield, FaFileAlt, FaCog, FaQuestionCircle, FaSignOutAlt } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';

const MobileSidebar = ({ user }) => {
    const [showUserMenu, setShowUserMenu] = useState(false);
    const userMenuRef = useRef(null);

    const getInitials = (name) => {
        if (!name) return '';
        const names = name.split(' ');
        const initials = names[0][0] + names[names.length - 1][0];
        return initials.toUpperCase();
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (userMenuRef.current && !userMenuRef.current.contains(event.target)) {
                setShowUserMenu(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const toggleUserMenu = () => {
        setShowUserMenu(!showUserMenu);
    };

    const handleLogout = () => {
        window.location.href = `${process.env.REACT_APP_API_URL}/api/auth/azuread/logout`;
    };

    return (
        <div className="d-flex bg-dark text-white align-items-center justify-content-around" style={{ height: '60px', position: 'fixed', top: 0, width: '100%', zIndex: 1 }}>
            <NavLink to="/events" className="text-white nav-link d-flex flex-column align-items-center">
                <FaUsers />
            </NavLink>
            <NavLink to="#" className="text-white nav-link d-flex flex-column align-items-center">
                <FaCalendarAlt />
            </NavLink>
            <NavLink to="/reports" className="text-white nav-link d-flex flex-column align-items-center">
                <FaFileAlt />
            </NavLink>
            <NavLink to="#" className="text-white nav-link d-flex flex-column align-items-center">
                <FaChartBar />
            </NavLink>
            <NavLink to="#" className="text-white nav-link d-flex flex-column align-items-center">
                <FaUserShield />
            </NavLink>
            <div
                style={{
                    width: '40px',
                    height: '40px',
                    borderRadius: '50%',
                    backgroundColor: '#4c47cc',
                    color: 'white',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer'
                }}
                onClick={toggleUserMenu}
            >
                {getInitials(user.name)}
            </div>
            {showUserMenu && (
                <div ref={userMenuRef} className="bg-white text-dark p-2 rounded shadow position-absolute" style={{ top: '60px', right: '10px', zIndex: '1000', width: '200px' }}>
                    <div className="text-dark d-flex align-items-center mb-2" style={{ cursor: 'pointer' }} onMouseEnter={e => e.currentTarget.style.backgroundColor = '#f0f0f0'} onMouseLeave={e => e.currentTarget.style.backgroundColor = 'transparent'}>
                        <FaCog className="me-2" /> Configurações
                    </div>
                    <div className="text-dark d-flex align-items-center mb-2" style={{ cursor: 'pointer' }} onMouseEnter={e => e.currentTarget.style.backgroundColor = '#f0f0f0'} onMouseLeave={e => e.currentTarget.style.backgroundColor = 'transparent'}>
                        <FaQuestionCircle className="me-2" /> Suporte
                    </div>
                    <div className="text-dark d-flex align-items-center" style={{ cursor: 'pointer' }} onClick={handleLogout} onMouseEnter={e => e.currentTarget.style.backgroundColor = '#f0f0f0'} onMouseLeave={e => e.currentTarget.style.backgroundColor = 'transparent'}>
                        <FaSignOutAlt className="me-2" /> Logout
                    </div>
                </div>
            )}
        </div>
    );
};

export default MobileSidebar;
