import React, { useState } from 'react';
import { FaRegCommentDots } from 'react-icons/fa';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';

const MobileNotification = ({ notifications, onNotificationClick }) => {
    const [show, setShow] = useState(false);

    const handleToggle = (isOpen) => {
        setShow(isOpen);
    };

    const markAsRead = async (notificationId) => {
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/api/notifications/mark-as-read`, { notificationId }, {
                withCredentials: true
            });
        } catch (error) {
            console.error('Error marking notification as read:', error);
        }
    };

    const handleClick = (notification) => {
        markAsRead(notification.id);
        onNotificationClick(notification);
    };

    return (
        <Dropdown show={show} onToggle={handleToggle} className="position-relative">
            <Dropdown.Toggle variant="link" id="dropdown-mobile-notification">
                <FaRegCommentDots className="text-primary" style={{ fontSize: '20px', cursor: 'pointer' }} /> {/* Ícone de mensagem ajustado para mobile */}
                {notifications.length > 0 && (
                    <span className="badge badge-danger position-absolute" style={{ top: '-5px', right: '-5px', fontSize: '12px' }}>
                        {notifications.length}
                    </span>
                )}
            </Dropdown.Toggle>

            <Dropdown.Menu align="right" style={{ width: '250px' }}>
                <Dropdown.Header>Notificações</Dropdown.Header>
                {notifications.length > 0 ? (
                    notifications.map((notification, index) => (
                        <Dropdown.Item 
                            as={Link} 
                            to={`/report/${notification.report_id}`} 
                            key={index} 
                            onClick={() => handleClick(notification)}
                            style={{ 
                                borderRadius: '8px', 
                                backgroundColor: index % 2 === 0 ? '#f8f9fa' : '#e9ecef', // Intercalar as cores de fundo
                                marginBottom: '4px', 
                                whiteSpace: 'normal', 
                                wordWrap: 'break-word', 
                                fontSize: '12px',  // Fonte ajustada para mobile
                                padding: '8px 10px' // Padding ajustado para mobile
                            }}
                        >
                            <strong>{notification.message.split(' ')[3]}</strong> {/* Destaque o subject */}
                            {notification.message.replace(notification.message.split(' ')[3], '')}
                        </Dropdown.Item>
                    ))
                ) : (
                    <Dropdown.Item 
                        style={{ 
                            borderRadius: '8px', 
                            backgroundColor: '#f8f9fa', 
                            whiteSpace: 'normal', 
                            wordWrap: 'break-word', 
                            fontSize: '12px',  // Fonte ajustada para mobile
                            padding: '8px 10px' // Padding ajustado para mobile
                        }}
                    >
                        Você não tem novas notificações.
                    </Dropdown.Item>
                )}
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default MobileNotification;
