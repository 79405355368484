import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useHistory } from 'react-router-dom';
import { FaArrowLeft, FaTags, FaUsers, FaDownload, FaShare } from 'react-icons/fa';
import ReactMarkdown from 'react-markdown';
import 'bootstrap/dist/css/bootstrap.min.css';
import MobileSidebar from '../components/MobileSidebar';

const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

const MobileReportDetail = ({ user }) => {
    const { id } = useParams();
    const [report, setReport] = useState(null);
    const [widgetUrl, setWidgetUrl] = useState('');
    const [playerWidgetUrl, setPlayerWidgetUrl] = useState('');
    const history = useHistory();
    const [colorMap, setColorMap] = useState({});

    useEffect(() => {
        const fetchReport = async () => {
            try {
                const token = localStorage.getItem('accessToken');
                if (!token) {
                    console.error('No access token found');
                    return;
                }

                const tokenResponse = await axios.get(`${REACT_APP_API_URL}/api/auth/azuread/token`, {
                    headers: { Authorization: `Bearer ${token}` },
                    withCredentials: true
                });

                if (tokenResponse.data && tokenResponse.data.accessToken) {
                    const reportResponse = await axios.get(`${REACT_APP_API_URL}/api/reports/${id}`, {
                        headers: { Authorization: `Bearer ${tokenResponse.data.accessToken}` },
                        withCredentials: true
                    });

                    if (reportResponse.data) {
                        setReport(reportResponse.data);

                        // Fetch video ID
                        const videoIdResponse = await axios.get(`${REACT_APP_API_URL}/api/videoindexer/videoId/${id}`);
                        const videoId = videoIdResponse.data.videoId;

                        // Fetch widget and player widget URLs
                        if (videoId) {
                            const widgetResponse = await axios.get(`${REACT_APP_API_URL}/api/videoindexer/widget/${videoId}`);
                            if (widgetResponse.data && widgetResponse.data.widgetUrl) {
                                setWidgetUrl(widgetResponse.data.widgetUrl);
                            } else {
                                console.error('No widget URL found in response');
                            }

                            const playerWidgetResponse = await axios.get(`${REACT_APP_API_URL}/api/videoindexer/playerwidget/${videoId}`);
                            if (playerWidgetResponse.data && playerWidgetResponse.data.playerWidgetUrl) {
                                setPlayerWidgetUrl(playerWidgetResponse.data.playerWidgetUrl);
                            } else {
                                console.error('No player widget URL found in response');
                            }
                        } else {
                            console.error('No video ID found in report data');
                        }
                    } else {
                        console.error('No report data found in response');
                    }
                } else {
                    console.error('No access token found in token response');
                }
            } catch (error) {
                console.error('Error fetching report or video indexer data:', error);
            }
        };

        fetchReport();
    }, [id]);

    const generateRandomColor = () => {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    };

    const getInitials = (name) => {
        const names = name.split(' ');
        const initials = names[0][0] + names[names.length - 1][0];
        if (!colorMap[initials]) {
            setColorMap(prevMap => ({ ...prevMap, [initials]: generateRandomColor() }));
        }
        return initials.toUpperCase();
    };

    const formatDateTime = (dateTime) => {
        const date = new Date(dateTime);
        return date.toLocaleString('pt-BR', {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
        });
    };

    const formatTime = (time) => {
        const date = new Date(`1970-01-01T${time}Z`);
        return date.toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false });
    };

    const renderTranscriptionContent = (content) => {
        const lines = content.split('\n').filter(line => line.trim() !== '');

        return lines.map((line, index) => {
            const match = line.match(/(\d{2}:\d{2}:\d{2}\.\d{3}) --> (\d{2}:\d{2}:\d{2}\.\d{3})/);
            if (match) {
                const time = `${formatTime(match[1])} - ${formatTime(match[2])}`;
                const speakerLine = lines[index + 1] || '';
                const speakerMatch = speakerLine.match(/<v ([^>]+)>(.+)<\/v>/);
                if (speakerMatch) {
                    const speaker = speakerMatch[1];
                    const text = speakerMatch[2];
                    const initials = getInitials(speaker);
                    const backgroundColor = colorMap[initials] || '#6c757d'; // Default color

                    return (
                        <div key={index} className="mb-3 d-flex">
                            <div className="mr-3 d-flex align-items-center justify-content-center" style={{ width: '40px', height: '40px', borderRadius: '50%', backgroundColor, color: 'white', textAlign: 'center' }}>
                                {initials}
                            </div>
                            <div style={{ flex: 1 }}>
                                <div className="text-muted small">{time}</div>
                                <div>{text}</div>
                            </div>
                        </div>
                    );
                }
            }
            return null;
        });
    };

    const handleVideoError = (event) => {
        console.error('Video error:', event.target.error);
    };

    const handleVideoLoad = (event) => {
        console.log('Video loaded successfully:', event.target.src);
    };

    if (!report) {
        return <div>Loading...</div>;
    }

    return (
        <div className="d-flex flex-column">
            <MobileSidebar user={user} />
            <div className="content flex-grow-1" style={{ marginTop: '60px', padding: '10px' }}>
                <div className="container mt-5">
                    <div className="d-flex justify-content-between align-items-center flex-wrap mb-4">
                        <div className="d-flex align-items-center mb-2 mb-md-0">
                            <FaArrowLeft
                                style={{ cursor: 'pointer', marginRight: '10px', color: '#6c757d' }}
                                onClick={() => history.goBack()}
                            />
                            <div>
                                <h2 className="mb-0">{report.subject}</h2>
                                <span style={{ color: '#6c757d' }}>{formatDateTime(report.transcription_created_date_time)}</span>
                            </div>
                        </div>
                        <div className="d-flex align-items-center">
                            {/* <span className="badge badge-primary" style={{ backgroundColor: '#7b84ec', color: 'white', marginRight: '15px' }}>
                                Relatório Premium
                            </span> */}
                            <div className="d-flex align-items-center" style={{ color: '#6c757d', marginRight: '15px' }}>
                                <FaTags style={{ marginRight: '5px' }} /> Tags (0)
                            </div>
                            <div className="d-flex align-items-center" style={{ color: '#6c757d', marginRight: '15px' }}>
                                <FaUsers style={{ marginRight: '5px' }} /> Participantes (5)
                            </div>
                            <FaDownload style={{ color: '#6c757d', marginRight: '15px', cursor: 'pointer' }} />
                            <button className="btn btn-primary ml-3" style={{ backgroundColor: '#4c47cc', borderColor: '#4c47cc' }}>
                                 <FaShare style={{ marginLeft: '5px' }} />
                            </button>
                        </div>
                    </div>
                    <hr />
                    <div className="row mt-4">
                        <div className="col-md-12">
                            <div className="card p-3 mb-4">
                                <h5>Resumo</h5>
                                <div className="p-3 mb-4" style={{ border: '1px solid #d0d0d0', borderRadius: '8px', maxHeight: '800px', overflowY: 'auto', paddingRight: '15px' }}>
                                    <ReactMarkdown className="text-muted small">{report.summary}</ReactMarkdown>
                                </div>
                                <h5>Transcrição</h5>
                                <div className="p-3" style={{ border: '1px solid #d0d0d0', borderRadius: '8px', maxHeight: '600px', overflowY: 'auto', paddingRight: '15px' }}>
                                    {renderTranscriptionContent(report.transcription_content)}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="d-flex align-items-center justify-content-center" style={{ width: '100%', height: '300px', backgroundColor: '#f0f0f0', border: '1px solid #d0d0d0', borderRadius: '8px' }}>
                                {widgetUrl && (
                                    <iframe
                                        src={playerWidgetUrl}
                                        width="100%"
                                        height="300px"
                                        style={{ border: 'none', marginTop: '20px' }}
                                        title="Video Insights Widget"
                                    ></iframe>
                                )}
                            </div>
                            {widgetUrl && (
                                <iframe
                                    src={widgetUrl}
                                    width="100%"
                                    height="900px"
                                    style={{ border: 'none', marginTop: '20px' }}
                                    title="Video Insights Widget"
                                ></iframe>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MobileReportDetail;
