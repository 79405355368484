import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaSearch, FaSync, FaCalendarAlt, FaChevronLeft, FaChevronRight, FaEllipsisV } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import MobileSidebar from '../components/MobileSidebar';
import { Tooltip, OverlayTrigger, Dropdown } from 'react-bootstrap';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { subDays } from 'date-fns';
import { ptBR } from 'date-fns/locale'; // Importar a localidade ptBR

const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

const MobileReports = ({ user }) => {
    const [reports, setReports] = useState([]);
    const [allReports, setAllReports] = useState([]);
    const [isFetching, setIsFetching] = useState(false);
    const [dateRange, setDateRange] = useState([
        {
            startDate: null,
            endDate: null,
            key: 'selection'
        }
    ]);
    const [showCalendar, setShowCalendar] = useState(false);
    const [sortOrder, setSortOrder] = useState('asc');
    const [titleSortOrder, setTitleSortOrder] = useState('asc');
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedTemporalFilter, setSelectedTemporalFilter] = useState(''); // Estado para filtro temporal
    const [itemsPerPage, setItemsPerPage] = useState(5); // Default is 5 items per page for mobile
    const [currentPage, setCurrentPage] = useState(1);
    const history = useHistory();
    const calendarRef = useRef(null);

    useEffect(() => {
        fetchReports();
    }, [dateRange]);

    useEffect(() => {
        updateDisplayedReports();
    }, [allReports, currentPage, itemsPerPage]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (calendarRef.current && !calendarRef.current.contains(event.target)) {
                setShowCalendar(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const fetchReports = async () => {
        setIsFetching(true);
        try {
            const token = localStorage.getItem('accessToken');
            if (!token) {
                history.push('/');
                return;
            }
            const tokenResponse = await axios.get(`${REACT_APP_API_URL}/api/auth/azuread/token`, {
                headers: { Authorization: `Bearer ${token}` },
                withCredentials: true
            });

            if (tokenResponse.data && tokenResponse.data.accessToken) {
                const reportsResponse = await axios.get(`${REACT_APP_API_URL}/api/reports/search`, {
                    headers: { Authorization: `Bearer ${tokenResponse.data.accessToken}` },
                    withCredentials: true,
                    params: {
                        userId: user.id,
                        searchQuery,
                        startDate: dateRange[0].startDate ? dateRange[0].startDate.toISOString() : undefined,
                        endDate: dateRange[0].endDate ? new Date(dateRange[0].endDate.setHours(23, 59, 59, 999)).toISOString() : undefined,
                    }
                });

                setAllReports(reportsResponse.data);
                setReports(reportsResponse.data.slice(0, itemsPerPage));
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                localStorage.removeItem('accessToken');
                history.push('/');
            } else {
                console.error("Error fetching reports:", error.message);
            }
        } finally {
            setIsFetching(false);
        }
    };

    const searchReports = async () => {
        setIsFetching(true);
        try {
            const token = localStorage.getItem('accessToken');
            if (!token) {
                history.push('/');
                return;
            }
            const tokenResponse = await axios.get(`${REACT_APP_API_URL}/api/auth/azuread/token`, {
                headers: { Authorization: `Bearer ${token}` },
                withCredentials: true
            });

            if (tokenResponse.data && tokenResponse.data.accessToken) {
                const searchResponse = await axios.get(`${REACT_APP_API_URL}/api/reports/search`, {
                    headers: { Authorization: `Bearer ${tokenResponse.data.accessToken}` },
                    withCredentials: true,
                    params: { userId: user.id, searchQuery }
                });

                setAllReports(searchResponse.data);
                setReports(searchResponse.data.slice(0, itemsPerPage));
                setCurrentPage(1);
            }
        } catch (error) {
            console.error("Error searching reports:", error.message);
        } finally {
            setIsFetching(false);
        }
    };

    const handleReload = async () => {
        await fetchReports();
    };

    const handleDateChange = (ranges) => {
        setDateRange([ranges.selection]);
        setSelectedTemporalFilter(''); // Resetar o filtro temporal quando a data é selecionada manualmente
    };

    const handleFilter = () => {
        const startDate = new Date(dateRange[0].startDate.setHours(0, 0, 0, 0)); // Início do dia
        const endDate = new Date(dateRange[0].endDate.setHours(23, 59, 59, 999)); // Fim do dia
        if (endDate - startDate <= 2 * 24 * 60 * 60 * 1000) {
            setDateRange([{ startDate, endDate, key: 'selection' }]);
            fetchReports();
        } else {
            alert('Por favor, selecione um intervalo de no máximo 2 dias.');
        }
    };

    const handleTemporalFilterChange = (filter) => {
        setIsFetching(true); // Ativar o estado de carregamento
        let startDate;
        const endDate = new Date();

        switch (filter) {
            case 'Hoje':
                startDate = new Date();
                break;
            case 'Últimos 3 dias':
                startDate = subDays(new Date(), 2);
                break;
            case 'Últimos 7 dias':
                startDate = subDays(new Date(), 6);
                break;
            default:
                setIsFetching(false); // Desativar o estado de carregamento em caso de erro
                return;
        }

        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(23, 59, 59, 999);

        setDateRange([{ startDate, endDate, key: 'selection' }]);
        setSelectedTemporalFilter(filter);
        fetchReports().finally(() => setIsFetching(false)); // Desativar o estado de carregamento após a busca
    };

    const handleSortOrder = () => {
        const sortedReports = [...allReports].sort((a, b) => {
            const dateA = new Date(a.transcription_created_date_time);
            const dateB = new Date(b.transcription_created_date_time);
            return sortOrder === 'asc' ? dateB - dateA : dateA - dateB;
        });
        setAllReports(sortedReports);
        updateDisplayedReports();
        setSortOrder(prevOrder => (prevOrder === 'asc' ? 'desc' : 'asc'));
    };

    const handleTitleSortOrder = () => {
        const sortedReports = [...allReports].sort((a, b) => {
            const titleA = a.subject.toLowerCase();
            const titleB = b.subject.toLowerCase();
            if (titleA < titleB) return titleSortOrder === 'asc' ? -1 : 1;
            if (titleA > titleB) return titleSortOrder === 'asc' ? 1 : -1;
            return 0;
        });
        setAllReports(sortedReports);
        updateDisplayedReports();
        setTitleSortOrder(prevOrder => (prevOrder === 'asc' ? 'desc' : 'asc'));
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            searchReports();
        }
    };

    const truncateTitle = (title, maxLength) => {
        if (title.length > maxLength) {
            return `${title.substring(0, maxLength)}...`;
        }
        return title;
    };

    const formatDateTime = (dateTime) => {
        const date = new Date(dateTime);
        const options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
        const formattedDate = date.toLocaleDateString('pt-BR', options);
        const formattedTime = date.toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' });
        return { formattedDate, formattedTime };
    };

    const getInitials = (name) => {
        if (!name) return '';
        const names = name.split(' ');
        const initials = names[0][0] + names[names.length - 1][0];
        return initials.toUpperCase();
    };

    const handleRowClick = (id) => {
        history.push(`/report/${id}`);
    };

    const formatRangeDate = () => {
        const { startDate, endDate } = dateRange[0];
        if (!startDate || !endDate) {
            return "Escolher Data";
        }
        const start = startDate.toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' });
        const end = endDate.toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' });
        return `${start} - ${end}`;
    };

    const handleButtonClick = () => {
        setShowCalendar(!showCalendar);
    };

    const clearDateFilter = () => {
        setDateRange([{ startDate: null, endDate: null, key: 'selection' }]);
        setSelectedTemporalFilter('');
        fetchReports();
    };

    const handleItemsPerPageChange = (e) => {
        setItemsPerPage(parseInt(e.target.value, 10));
        setCurrentPage(1);
        updateDisplayedReports();
    };

    const handleNextPage = () => {
        setCurrentPage(prevPage => prevPage + 1);
        updateDisplayedReports();
    };

    const handlePreviousPage = () => {
        setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
        updateDisplayedReports();
    };

    const updateDisplayedReports = () => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        setReports(allReports.slice(startIndex, endIndex));
    };

    const deleteReport = async (reportId) => {
        try {
            const token = localStorage.getItem('accessToken');
            if (!token) {
                history.push('/');
                return;
            }
            const tokenResponse = await axios.get(`${REACT_APP_API_URL}/api/auth/azuread/token`, {
                headers: { Authorization: `Bearer ${token}` },
                withCredentials: true
            });

            if (tokenResponse.data && tokenResponse.data.accessToken) {
                await axios.delete(`${REACT_APP_API_URL}/api/reports/${reportId}`, {
                    headers: { Authorization: `Bearer ${tokenResponse.data.accessToken}` },
                    withCredentials: true
                });

                setAllReports(prevReports => prevReports.filter(report => report.id !== reportId));
                setReports(prevReports => prevReports.filter(report => report.id !== reportId));
            }
        } catch (error) {
            console.error("Error deleting report:", error.message);
        }
    };

    return (
        <div className="d-flex flex-column">
            <MobileSidebar user={user} />
            <div className="content flex-grow-1" style={{ marginTop: '60px', padding: '10px' }}>
                <div className="d-flex flex-column align-items-center mb-4" style={{ backgroundColor: '#e0e0e0', padding: '10px', borderRadius: '5px' }}>
                    <h2 style={{ fontSize: '18px' }}>Relatórios</h2>
                    <div className="d-flex flex-column align-items-center">
                        <div className="d-flex mb-2">
                            <input 
                                type="text" 
                                value={searchQuery} 
                                onChange={e => setSearchQuery(e.target.value)} 
                                onKeyPress={handleKeyPress}
                                className="form-control mr-2" 
                                placeholder="Buscar Relatório" 
                                style={{ maxWidth: '200px', paddingRight: '30px' }} 
                            />
                            <OverlayTrigger
                                placement="bottom"
                                overlay={<Tooltip id="button-tooltip">Buscar relatório</Tooltip>}
                            >
                                <FaSearch 
                                    onClick={searchReports}
                                    style={{
                                        cursor: 'pointer',
                                        color: '#6c757d'
                                    }}
                                />
                            </OverlayTrigger>
                        </div>
                        <div className="d-flex align-items-center border" style={{ borderRadius: '5px', borderColor: '#e0e0e0', padding: '5px 10px' }}>
                            <Dropdown onSelect={handleTemporalFilterChange} className="mr-3">
                                <Dropdown.Toggle variant="light" id="dropdown-basic">
                                    {selectedTemporalFilter || 'Escolher Data'}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item eventKey="Hoje">Hoje</Dropdown.Item>
                                    <Dropdown.Item eventKey="Últimos 3 dias">Últimos 3 dias</Dropdown.Item>
                                    <Dropdown.Item eventKey="Últimos 7 dias">Últimos 7 dias</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            <div className="vr" style={{ height: '28px', alignSelf: 'center', borderColor: '#e0e0e0', marginLeft: '10px', marginRight: '10px' }}></div>
                            <OverlayTrigger
                                placement="bottom"
                                overlay={<Tooltip id="button-tooltip">Escolher Data</Tooltip>}
                            >
                                <button 
                                    className="btn btn-light"
                                    onClick={handleButtonClick}
                                    style={{
                                        color: '#6c757d', // cor da fonte cinza
                                        transition: 'transform 0.2s'
                                    }}
                                >
                                    <FaCalendarAlt /> {formatRangeDate()}
                                </button>
                            </OverlayTrigger>
                            {showCalendar && (
                                <div ref={calendarRef} className="position-absolute" style={{ zIndex: 1000, backgroundColor: 'white', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', borderRadius: '5px', top: '50px' }}>
                                    <DateRange
                                        ranges={dateRange}
                                        onChange={handleDateChange}
                                        showSelectionPreview={true}
                                        moveRangeOnFirstSelection={false}
                                        months={1}
                                        direction="horizontal"
                                        locale={ptBR} // Definir a localidade para Português do Brasil
                                        className="mr-2"
                                    />
                                    <button onClick={clearDateFilter} className="btn btn-secondary mt-2 ml-2">Limpar</button>
                                </div>
                            )}
                        </div>
                        <button onClick={handleReload} className="btn btn-link mt-2" style={{ textDecoration: 'none', color: '#4c47cc', fontSize: '14px' }}>
                            <FaSync style={{ marginRight: '5px' }} /> Recarregar
                        </button>
                    </div>
                </div>
                {isFetching ? (
                    <div className="d-flex justify-content-center align-items-center" style={{ height: '300px' }}>
                        <div className="spinner-border text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                ) : (
                    <>
                        <div className="list-group">
                            <div className="list-group-item bg-light d-flex justify-content-between align-items-center" style={{ fontSize: '14px' }}>
                                <span style={{ flex: 3, cursor: 'pointer' }} onClick={handleTitleSortOrder}>
                                    Relatório {titleSortOrder === 'asc' ? '▲' : '▼'}
                                </span>
                                <span style={{ flex: 2, cursor: 'pointer' }} onClick={handleSortOrder}>
                                    Data {sortOrder === 'asc' ? '▲' : '▼'}
                                </span>
                                <span style={{ flex: 1 }}>Owner</span>
                                <span style={{ flex: 0.5, textAlign: 'right' }}>Ações</span>
                            </div>
                            {reports.map(report => (
                                <div key={report.id} className="list-group-item mb-3 rounded shadow-sm d-flex justify-content-between align-items-center" style={{ fontSize: '14px', transition: 'transform 0.2s, box-shadow 0.2s' }}>
                                    <div className="d-flex align-items-center" style={{ flex: 11 }} onClick={() => handleRowClick(report.id)}>
                                        <div style={{ flex: 3 }}>
                                            <h6 className="mb-0" title={report.subject} style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'normal' }}>
                                                {report.subject}
                                            </h6>
                                        </div>
                                        <div style={{ flex: 2, textAlign: 'left', fontSize: '12px' }}>
                                            {report.transcription_created_date_time ? (
                                                <>
                                                    <div>{formatDateTime(report.transcription_created_date_time).formattedDate}</div>
                                                    <div className="d-flex align-items-center">
                                                        <span>{formatDateTime(report.transcription_created_date_time).formattedTime}</span>
                                                    </div>
                                                </>
                                            ) : 'No date available'}
                                        </div>
                                        <div style={{ flex: 1 }}>
                                            <div
                                                style={{
                                                    width: '30px',
                                                    height: '30px',
                                                    borderRadius: '50%',
                                                    backgroundColor: '#4c47cc',
                                                    color: 'white',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    fontSize: '12px'
                                                }}
                                            >
                                                {getInitials(report.user_name)}
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ flex: 1 }} className="d-flex justify-content-end">
                                        <Dropdown>
                                            <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                                                <FaEllipsisV />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={(e) => {
                                                    e.stopPropagation(); 
                                                    deleteReport(report.id);
                                                }}>Excluir</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="d-flex justify-content-end align-items-center mt-3">
                            <span>{`${(currentPage - 1) * itemsPerPage + 1}-${Math.min(currentPage * itemsPerPage, allReports.length)} de ${allReports.length}`}</span>
                            <div className="btn-group ml-3">
                                <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip id="button-tooltip">Página anterior</Tooltip>}
                                >
                                    <button className="btn btn-secondary" onClick={handlePreviousPage} disabled={currentPage === 1} style={{ backgroundColor: 'white', borderColor: '#e0e0e0', color: '#6c757d', marginRight: '2px' }}>
                                        <FaChevronLeft />
                                    </button>
                                </OverlayTrigger>
                                <select id="itemsPerPage" className="form-control" value={itemsPerPage} onChange={handleItemsPerPageChange} style={{ maxWidth: '70px', borderColor: '#e0e0e0', marginRight: '2px' }}>
                                    <option value={5}>5</option>
                                    <option value={10}>10</option>
                                    <option value={15}>15</option>
                                </select>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip id="button-tooltip">Próxima página</Tooltip>}
                                >
                                    <button className="btn btn-secondary" onClick={handleNextPage} style={{ backgroundColor: 'white', borderColor: '#e0e0e0', color: '#6c757d', marginRight: '20px' }}>
                                        <FaChevronRight />
                                    </button>
                                </OverlayTrigger>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
        href=""
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
    </a>
));

export default MobileReports;
