import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import teamsLogo from '../assets/teams-logo.png';
import Cookies from 'js-cookie';

const REACT_APP_URL_OUTSYSTEMS = process.env.REACT_APP_URL_OUTSYSTEMS;
const Login = () => {
    const history = useHistory();


    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');
        console.log("Login Component: Token from URL params:", token);
        
        if (token) {
                        
            const cookieValue = Cookies.get('connect.sid');
            console.log("acookieValue", cookieValue);
            const encodedString = btoa(cookieValue);
            localStorage.setItem('accessToken', token);
            window.location.href = REACT_APP_URL_OUTSYSTEMS+encodedString;
      
        } else if (localStorage.getItem('accessToken')) {
       
        const cookieValue = Cookies.get('connect.sid');
        console.log("cookieValue", cookieValue);
        const encodedString = btoa(cookieValue);
        window.location.href = REACT_APP_URL_OUTSYSTEMS+encodedString;
     
        }


    }, [history]);

    return (
        <div className="container-fluid vh-100 d-flex">
            <div className="row w-100">
                <div className="col-md-6 d-flex flex-column justify-content-center align-items-center bg-white">
                    <img src={teamsLogo} alt="Logo" className="mb-4" style={{ width: '100px' }} />
                    <div className="text-center">
                        <h2 className="mb-3">Sign in</h2>
                        <a href={`${process.env.REACT_APP_API_URL}/api/auth/azuread/login`} className="btn btn-primary mb-3">Login com Microsoft Entra</a>
                        {/* <p className="mb-2">or</p> */}
                        <div className="d-flex justify-content-center">
                            {/* <button className="btn btn-outline-secondary mb-2 mx-1">Sign in with Google</button>
                            <button className="btn btn-outline-secondary mb-2 mx-1">Sign in with Zoom</button>
                            <button className="btn btn-outline-secondary mb-2 mx-1">Sign in with Slack</button> */}
                        </div>
                    </div>
                </div>
                <div className="col-md-6 d-flex flex-column justify-content-center align-items-center bg-primary text-white">
                    <div className="text-center">
                        <h3>Vídeo playback com AI highlights</h3>
                        <div className="embed-responsive embed-responsive-16by9 mt-3">
                            <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/n-ub_VdpkAI" allowFullScreen title="YouTube video"></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
