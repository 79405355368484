import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import teamsLogo from '../../assets/teams-logo.png';
import aiIcon from '../../assets/img/ai-icon.png';
import clockIcon from '../../assets/img/icone-clock.png';
import { FaSearch, FaSync, FaChevronLeft, FaChevronRight, FaCalendarAlt, FaTimes } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import MobileSidebar from '../components/MobileSidebar';
import MobileNotification from '../components/MobileNotification'; // Importe o componente MobileNotification
import { Tooltip, OverlayTrigger, Dropdown } from 'react-bootstrap';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { addDays, subDays } from 'date-fns';
import { ptBR } from 'date-fns/locale'; // Importar a localidade ptBR

const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

const MobileEvents = ({ user }) => {
    const [events, setEvents] = useState([]);
    const [allEvents, setAllEvents] = useState([]);
    const [summary, setSummary] = useState('');
    const [loading, setLoading] = useState({});
    const [isFetching, setIsFetching] = useState(false);
    const [dateRange, setDateRange] = useState([
        {
            startDate: null,
            endDate: null,
            key: 'selection'
        }
    ]);
    const [showCalendar, setShowCalendar] = useState(false);
    const [sortOrder, setSortOrder] = useState('asc');
    const [titleSortOrder, setTitleSortOrder] = useState('asc');
    const [searchQuery, setSearchQuery] = useState('');
    const [summarizedEvents, setSummarizedEvents] = useState(new Set());
    const [notifications, setNotifications] = useState([]);
    const [isAnimating, setIsAnimating] = useState(false); // Estado para controle de animação
    const [selectedTemporalFilter, setSelectedTemporalFilter] = useState(''); // Estado para filtro temporal
    const history = useHistory();
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const [currentPage, setCurrentPage] = useState(1);

    const calendarRef = useRef(null);

    useEffect(() => {
        fetchEvents();
        fetchNotifications();
    }, [dateRange]);

    useEffect(() => {
        updateDisplayedEvents();
    }, [allEvents, currentPage, itemsPerPage]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (calendarRef.current && !calendarRef.current.contains(event.target)) {
                setShowCalendar(false);
            }
        };

        if (showCalendar) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showCalendar]);

    const fetchEvents = async () => {
        setIsFetching(true);
        try {
            const tokenResponse = await axios.get(`${REACT_APP_API_URL}/api/auth/azuread/token`, {
                withCredentials: true
            });

            if (tokenResponse.data && tokenResponse.data.accessToken) {
                localStorage.setItem('accessToken', tokenResponse.data.accessToken);

                const eventsResponse = await axios.get(`${REACT_APP_API_URL}/api/events`, {
                    headers: { Authorization: `Bearer ${tokenResponse.data.accessToken}` },
                    withCredentials: true,
                    params: {
                        startDate: dateRange[0].startDate ? dateRange[0].startDate.toISOString() : undefined,
                        endDate: dateRange[0].endDate ? new Date(dateRange[0].endDate.setHours(23, 59, 59, 999)).toISOString() : undefined, // Ajusta o horário da endDate
                    }
                });

                setAllEvents(eventsResponse.data);
                setEvents(eventsResponse.data.slice(0, itemsPerPage));
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                localStorage.removeItem('accessToken');
                history.push('/login');
            } else {
                console.error("Error fetching events:", error.message);
            }
        } finally {
            setIsFetching(false);
        }
    };

    const fetchNotifications = async () => {
        try {
            const tokenResponse = await axios.get(`${REACT_APP_API_URL}/api/auth/azuread/token`, {
                withCredentials: true
            });

            if (tokenResponse.data && tokenResponse.data.accessToken) {
                localStorage.setItem('accessToken', tokenResponse.data.accessToken);

                const notificationsResponse = await axios.get(`${REACT_APP_API_URL}/api/notifications`, {
                    headers: { Authorization: `Bearer ${tokenResponse.data.accessToken}` },
                    withCredentials: true,
                    params: { userId: user.id }
                });

                setNotifications(notificationsResponse.data);
            }
        } catch (error) {
            console.error("Error fetching notifications:", error.message);
        }
    };

    const searchEvents = async () => {
        setIsFetching(true);
        try {
            const tokenResponse = await axios.get(`${REACT_APP_API_URL}/api/auth/azuread/token`, {
                withCredentials: true
            });

            if (tokenResponse.data && tokenResponse.data.accessToken) {
                localStorage.setItem('accessToken', tokenResponse.data.accessToken);

                const searchResponse = await axios.get(`${REACT_APP_API_URL}/api/events/search`, {
                    headers: { Authorization: `Bearer ${tokenResponse.data.accessToken}` },
                    withCredentials: true,
                    params: { searchQuery }
                });

                setAllEvents(searchResponse.data);
                setEvents(searchResponse.data.slice(0, itemsPerPage));
                setCurrentPage(1);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                localStorage.removeItem('accessToken');
                history.push('/login');
            } else {
                console.error("Error searching events:", error.message);
            }
        } finally {
            setIsFetching(false);
        }
    };

    const handleReload = async () => {
        await fetchEvents();
        await fetchNotifications();
    };

    const handleSummarize = async (transcriptContentUrl, event) => {
        setLoading(prev => ({ ...prev, [event.id]: true }));
        try {
            const tokenResponse = await axios.get(`${REACT_APP_API_URL}/api/auth/azuread/token`, {
                withCredentials: true
            });

            if (tokenResponse.data && tokenResponse.data.accessToken) {
                localStorage.setItem('accessToken', tokenResponse.data.accessToken);

                const transcriptResponse = await axios.get(`${transcriptContentUrl}?$format=text/vtt`, {
                    headers: { Authorization: `Bearer ${tokenResponse.data.accessToken}` }
                });

                const transcriptText = transcriptResponse.data;

                const response = await axios.post(`${REACT_APP_API_URL}/api/summary/summarize`, {
                    transcriptContentUrl: transcriptContentUrl,
                    accessToken: tokenResponse.data.accessToken
                });

                const finalSummary = response.data.summary;

                const transcriptionCreatedDateTime = new Date(event.transcriptionCreatedDateTime).toISOString().slice(0, 19).replace('T', ' ');

                const saveResponse = await axios.post(`${REACT_APP_API_URL}/api/summary/save`, {
                    meetingId: event.id,
                    subject: event.subject,
                    joinUrl: event.joinUrl,
                    transcriptionUrl: transcriptContentUrl,
                    transcriptionContent: transcriptText,
                    transcriptionCreatedDateTime: transcriptionCreatedDateTime,
                    summary: finalSummary,
                    userId: user.id,
                    recordingUrl: event.recordingContentUrl,
                    userName: user.name,
                    userUpn: user.email
                });

                setSummary(finalSummary);
                const newNotification = {
                    id: saveResponse.data.reportId,
                    report_id: saveResponse.data.reportId,
                    message: `Seu resumo do ${event.subject} está pronto. Clique para ver.`
                };
                setNotifications(prev => [...prev, newNotification]);
                setSummarizedEvents(prev => new Set(prev).add(event.id));
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                localStorage.removeItem('accessToken');
                history.push('/login');
            } else {
                console.error("Error summarizing transcript:", error.response ? error.response.data : error.message);
            }
        } finally {
            setLoading(prev => ({ ...prev, [event.id]: false }));
        }
    };

    const handleDateChange = (ranges) => {
        setDateRange([ranges.selection]);
        setSelectedTemporalFilter(''); // Resetar o filtro temporal quando a data é selecionada manualmente
    };

    const handleFilter = () => {
        const startDate = new Date(dateRange[0].startDate.setHours(0, 0, 0, 0)); // Início do dia
        const endDate = new Date(dateRange[0].endDate.setHours(23, 59, 59, 999)); // Fim do dia
        if (endDate - startDate <= 2 * 24 * 60 * 60 * 1000) {
            setDateRange([{ startDate, endDate, key: 'selection' }]);
            fetchEvents();
        } else {
            alert('Por favor, selecione um intervalo de no máximo 2 dias.');
        }
    };

    const handleTemporalFilterChange = (filter) => {
        setIsFetching(true); // Ativar o estado de carregamento
        let startDate;
        const endDate = new Date();

        switch (filter) {
            case 'Hoje':
                startDate = new Date();
                break;
            case 'Últimos 3 dias':
                startDate = subDays(new Date(), 2);
                break;
            case 'Últimos 7 dias':
                startDate = subDays(new Date(), 6);
                break;
            default:
                setIsFetching(false); // Desativar o estado de carregamento em caso de erro
                return;
        }

        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(23, 59, 59, 999);

        setDateRange([{ startDate, endDate, key: 'selection' }]);
        setSelectedTemporalFilter(filter);
        fetchEvents().finally(() => setIsFetching(false)); // Desativar o estado de carregamento após a busca
    };

    const handleSortOrder = () => {
        const sortedEvents = [...allEvents].sort((a, b) => {
            const dateA = new Date(a.transcriptionCreatedDateTime);
            const dateB = new Date(b.transcriptionCreatedDateTime);
            return sortOrder === 'asc' ? dateB - dateA : dateA - dateB;
        });
        setAllEvents(sortedEvents);
        updateDisplayedEvents();
        setSortOrder(prevOrder => (prevOrder === 'asc' ? 'desc' : 'asc'));
    };

    const handleTitleSortOrder = () => {
        const sortedEvents = [...allEvents].sort((a, b) => {
            const titleA = a.subject.toLowerCase();
            const titleB = b.subject.toLowerCase();
            if (titleA < titleB) return titleSortOrder === 'asc' ? -1 : 1;
            if (titleA > titleB) return titleSortOrder === 'asc' ? 1 : -1;
            return 0;
        });
        setAllEvents(sortedEvents);
        updateDisplayedEvents();
        setTitleSortOrder(prevOrder => (prevOrder === 'asc' ? 'desc' : 'asc'));
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            searchEvents();
        }
    };

    const handleNotificationClick = (notification) => {
        history.push(`/report/${notification.report_id}`);
        setNotifications(prev => prev.filter(notif => notif !== notification));
    };

    const truncateTitle = (title, maxLength) => {
        if (title.length > maxLength) {
            return `${title.substring(0, maxLength)}...`;
        }
        return title;
    };

    const formatDateTime = (dateTime) => {
        const date = new Date(dateTime);
        const options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
        const formattedDate = date.toLocaleDateString('pt-BR', options);
        const formattedTime = date.toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' });
        return { formattedDate, formattedTime };
    };

    const handleVideoError = (event) => {
        console.error('Video error:', event.target.error);
    };

    const handleVideoLoad = (event) => {
        console.log('Video loaded successfully:', event.target.src);
    };

    const handleItemsPerPageChange = (e) => {
        setItemsPerPage(parseInt(e.target.value, 10));
        setCurrentPage(1);
        updateDisplayedEvents();
    };

    const handleNextPage = () => {
        setCurrentPage(prevPage => prevPage + 1);
        updateDisplayedEvents();
    };

    const handlePreviousPage = () => {
        setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
        updateDisplayedEvents();
    };

    const updateDisplayedEvents = () => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        setEvents(allEvents.slice(startIndex, endIndex));
    };

    const formatRangeDate = () => {
        const { startDate, endDate } = dateRange[0];
        if (!startDate || !endDate) {
            return "Escolher Data";
        }
        const start = startDate.toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' });
        const end = endDate.toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' });
        return `${start} - ${end}`;
    };

    const handleButtonClick = () => {
        setIsAnimating(true);
        setTimeout(() => setIsAnimating(false), 300); // Duração da animação
        setShowCalendar(!showCalendar);
    };

    const clearDateFilter = () => {
        setDateRange([{ startDate: null, endDate: null, key: 'selection' }]);
        setSelectedTemporalFilter('');
        fetchEvents();
    };

    return (
        <div className="d-flex flex-column">
            <MobileSidebar user={user} />
            <div className="content flex-grow-1" style={{ marginTop: '60px', padding: '10px' }}>
                <div className="d-flex flex-column align-items-center mb-4" style={{ backgroundColor: '#e0e0e0', padding: '10px', borderRadius: '5px' }}>
                    <h2 style={{ fontSize: '18px' }}>Reuniões</h2>
                    <div className="d-flex flex-column align-items-center">
                        <div className="d-flex mb-2">
                            <input 
                                type="text" 
                                value={searchQuery} 
                                onChange={e => setSearchQuery(e.target.value)} 
                                onKeyPress={handleKeyPress}
                                className="form-control mr-2" 
                                placeholder="Buscar Reunião" 
                                style={{ maxWidth: '150px', paddingRight: '30px', fontSize: '12px' }} 
                            />
                            <OverlayTrigger
                                placement="bottom"
                                overlay={<Tooltip id="button-tooltip">Buscar reunião</Tooltip>}
                            >
                                <FaSearch 
                                    onClick={searchEvents}
                                    style={{
                                        cursor: 'pointer',
                                        color: '#6c757d'
                                    }}
                                />
                            </OverlayTrigger>
                        </div>
                        <div className="d-flex mb-2">
                            <Dropdown onSelect={handleTemporalFilterChange} className="mr-3">
                                <Dropdown.Toggle variant="light" id="dropdown-basic" style={{ fontSize: '12px' }}>
                                    {selectedTemporalFilter || 'Escolher Data'}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item eventKey="Hoje">Hoje</Dropdown.Item>
                                    <Dropdown.Item eventKey="Últimos 3 dias">Últimos 3 dias</Dropdown.Item>
                                    <Dropdown.Item eventKey="Últimos 7 dias">Últimos 7 dias</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            <div className="vr" style={{ height: '28px', alignSelf: 'center', borderColor: '#e0e0e0', marginLeft: '10px', marginRight: '10px' }}></div>
                            <OverlayTrigger
                                placement="bottom"
                                overlay={<Tooltip id="button-tooltip">Escolher Data</Tooltip>}
                            >
                                <button 
                                    className="btn btn-light"
                                    onClick={handleButtonClick}
                                    style={{
                                        color: '#6c757d', // cor da fonte cinza
                                        transform: isAnimating ? 'scale(1.1)' : 'scale(1)',
                                        transition: 'transform 0.2s',
                                        fontSize: '12px'
                                    }}
                                >
                                    <FaCalendarAlt /> {formatRangeDate()}
                                </button>
                            </OverlayTrigger>
                            {showCalendar && (
                                <div ref={calendarRef} className="position-absolute" style={{ zIndex: 1000, backgroundColor: 'white', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', borderRadius: '5px', top: '50px' }}>
                                    <DateRange
                                        ranges={dateRange}
                                        onChange={handleDateChange}
                                        showSelectionPreview={true}
                                        moveRangeOnFirstSelection={false}
                                        months={1}
                                        direction="vertical"
                                        locale={ptBR} // Definir a localidade para Português do Brasil
                                        className="mr-2"
                                    />
                                    {/* <button onClick={handleFilter} className="btn btn-primary mt-2">Filtrar</button> */}
                                    <button onClick={clearDateFilter} className="btn btn-secondary mt-2 ml-2">Limpar</button>
                                </div>
                            )}
                        </div>
                        <div className="d-flex justify-content-between w-100">
                            <OverlayTrigger
                                placement="bottom"
                                overlay={<Tooltip id="button-tooltip">Recarregar</Tooltip>}
                            >
                                <button onClick={handleReload} className="btn btn-link" style={{ textDecoration: 'none', color: '#4c47cc', fontSize: '12px' }}>
                                    <FaSync style={{ marginRight: '5px' }} /> Recarregar
                                </button>
                            </OverlayTrigger>
                            <MobileNotification notifications={notifications} onNotificationClick={handleNotificationClick} /> {/* Adicionando a notificação */}
                        </div>
                    </div>
                </div>
                {isFetching ? (
                    <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
                        <div className="spinner-border text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                ) : (
                    <>
                        <div className="list-group">
                            <div className="list-group-item bg-light d-flex justify-content-between align-items-center" style={{ fontSize: '12px' }}>
                                <span style={{ flex: 1 }}>Origem</span>
                                <span style={{ flex: 2, cursor: 'pointer' }} onClick={handleTitleSortOrder}>
                                    Reunião {titleSortOrder === 'asc' ? '▲' : '▼'}
                                </span>
                                <span style={{ flex: 1, cursor: 'pointer' }} onClick={handleSortOrder}>
                                    Data {sortOrder === 'asc' ? '▲' : '▼'}
                                </span>
                                <span style={{ flex: 1 }}>Tags</span>
                                <span style={{ flex: 1 }}>GenAI</span>
                            </div>
                            {!searchQuery && !dateRange[0].startDate && !dateRange[0].endDate && allEvents.length === 0 && (
                                <div className="d-flex flex-column align-items-center justify-content-center" style={{ height: '200px', border: '1px solid #e0e0e0', borderRadius: '10px', backgroundColor: '#f8f9fa', marginTop: '10px' }}>
                                    <FaCalendarAlt size={20} color="#007bff" style={{ marginBottom: '10px' }} />
                                    <p style={{ color: '#6c757d', fontSize: '12px' }}>Nenhum filtro selecionado.</p>
                                    <p style={{ color: '#6c757d', fontSize: '12px' }}>Por favor, aplique um filtro para ver as reuniões.</p>
                                </div>
                            )}
                            {events.map(event => (
                                <div key={event.id} className="list-group-item mb-3 rounded shadow-sm" style={{ fontSize: '12px', transition: 'transform 0.2s, box-shadow 0.2s' }} onMouseEnter={e => { e.currentTarget.style.transform = 'translateY(-5px)'; e.currentTarget.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.1)'; }} onMouseLeave={e => { e.currentTarget.style.transform = 'translateY(0)'; e.currentTarget.style.boxShadow = '0 1px 2px rgba(0, 0, 0, 0.05)'; }}>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div style={{ flex: 1 }}>
                                            {event.recordingContentUrl ? (
                                                <video width="80" controls onError={handleVideoError} onLoadedData={handleVideoLoad}>
                                                    <source src={`${REACT_APP_API_URL}/api/recording/get-recording?recordingUrl=${encodeURIComponent(event.recordingContentUrl)}`} type="video/mp4" />
                                                    Your browser does not support the video tag.
                                                </video>
                                            ) : (
                                                <img src={teamsLogo} alt="Teams Logo" style={{ width: '30px', marginRight: '10px' }} />
                                            )}
                                        </div>
                                        <div style={{ flex: 2 }}>
                                            <h6 className="mb-0" title={event.subject} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                {truncateTitle(event.subject, 20)}
                                            </h6>
                                        </div>
                                        <div style={{ flex: 1, textAlign: 'left', fontSize: '10px' }}>
                                            {event.transcriptionCreatedDateTime ? (
                                                <>
                                                    <div>{formatDateTime(event.transcriptionCreatedDateTime).formattedDate}</div>
                                                    <div className="d-flex align-items-center">
                                                        <span>{formatDateTime(event.transcriptionCreatedDateTime).formattedTime}</span>
                                                        <img
                                                            src={clockIcon}
                                                            alt="Clock Icon"
                                                            style={{ width: '12px', height: '12px', marginLeft: '5px' }}
                                                        />
                                                    </div>
                                                </>
                                            ) : 'No date available'}
                                        </div>
                                        <div style={{ flex: 1 }}>
                                            {/* Placeholder for Tags */}
                                        </div>
                                        <div style={{ flex: 1 }}>
                                            {event.transcriptContentUrl ? (
                                                <OverlayTrigger
                                                    placement="bottom"
                                                    overlay={<Tooltip id="summarize-tooltip">Summarize</Tooltip>}
                                                >
                                                    <img
                                                        src={aiIcon}
                                                        alt="Summarize"
                                                        onClick={() => handleSummarize(event.transcriptContentUrl, event)}
                                                        className={`ml-2 ${loading[event.id] ? 'spinner-border' : ''}`}
                                                        style={{ width: '30px', height: '30px', cursor: 'pointer' }}
                                                        disabled={summarizedEvents.has(event.id)}
                                                    />
                                                </OverlayTrigger>
                                            ) : (
                                                <div style={{ width: '30px', height: '30px', marginLeft: '10px' }}></div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="d-flex justify-content-end align-items-center mt-3">
                            <span>{`${(currentPage - 1) * itemsPerPage + 1}-${Math.min(currentPage * itemsPerPage, allEvents.length)} de ${allEvents.length}`}</span>
                            <div className="btn-group ml-3">
                                <OverlayTrigger
                                    placement="bottom"
                                    overlay={<Tooltip id="button-tooltip">Página anterior</Tooltip>}
                                >
                                    <button className="btn btn-secondary" onClick={handlePreviousPage} disabled={currentPage === 1} style={{ backgroundColor: 'white', borderColor: '#e0e0e0', color: '#6c757d', marginRight: '2px' }}>
                                        <FaChevronLeft />
                                    </button>
                                </OverlayTrigger>
                                <select id="itemsPerPage" className="form-control" value={itemsPerPage} onChange={handleItemsPerPageChange} style={{ maxWidth: '50px', borderColor: '#e0e0e0', marginRight: '2px', fontSize: '12px', padding: '0.25rem' }}>
                                    <option value={5}>5</option>
                                    <option value={10}>10</option>
                                    <option value={15}>15</option>
                                </select>
                                <OverlayTrigger
                                    placement="bottom"
                                    overlay={<Tooltip id="button-tooltip">Próxima página</Tooltip>}
                                >
                                    <button className="btn btn-secondary" onClick={handleNextPage} style={{ backgroundColor: 'white', borderColor: '#e0e0e0', color: '#6c757d' }}>
                                        <FaChevronRight />
                                    </button>
                                </OverlayTrigger>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default MobileEvents;
